<template>
  <div class="contact">
    <h1> Contact </h1>
  </div>
</template>
<script>
  export default{
    name: 'ContactPage,'
  };
</script>
